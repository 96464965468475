
import Vue from "vue";
import { StoreTypes } from "@/assets/js/Types";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
export default Vue.extend({
  props: ["showCenter"],
  data() {
    return {
      projectInfo: this.$store.state.project[StoreTypes.XC_PROJECT_CURRENT],
      map: null as any,
      centerImg: require("@/assets/img/icon/center.png"),
      geocoder: {} as any,
      devices: [] as any[],
      polyline: null as any,
      polygon: null as any,
      polyLines: [] as any[],
      labelsLay: {} as any
    };
  },
  methods: {
    init(nomarl: any) {
      if (this.map) {
        return;
      }
      const win = (window as any).AMap;
      this.map = new win.Map("container", {
        zoom: 15.5, //级别
        center: [
          this.projectInfo.lng || 120.281516,
          this.projectInfo.lat || 29.94306,
        ], //中心点坐标
        viewMode: "3D", //使用3D视图,
        mapStyle: nomarl ? "" : "amap://styles/f9e5bdd6a5955636614e45b3d8000ffa",
      });
      this.map.on("click", (e: any) => {
        this.$emit("mapClick", e);
      });
      this.map.on("rightclick", (e: any) => {
        this.$emit("mapRightClick", e);
      });
      this.map.on("mousemove", (e: any) => {
        this.$emit("mapMouseMove", e);
      });
      this.map.on("moveend", this.mapMoveEnd);
      win.plugin("AMap.Geocoder", () => {
        const geo = new win.Geocoder({
          city: "全国",
        });
        this.geocoder = geo;
      });
      this.labelsLay = new win.LabelsLayer(
        {zooms: [3, 20],
        zIndex: 1000,
        collision: false, //该层内标注是否避让
        allowCollision: false, //不同标注层之间是否避让  

      });
      this.map.add(this.labelsLay);
    },
    reset() {
      this.setCenter(this.projectInfo.lat, this.projectInfo.lng);
    },
    setAddress(address: string) {
      this.getPostionByAddress(address, (lat: number, lng: number) => {
        this.setCenter(lat, lng);
      });
    },
    setCenter(lat: number, lng: number) {
      const w: any = window;
      var center = new w.AMap.LngLat(lng, lat);
      this.map.setZoomAndCenter(20, [lng, lat]);
    },
    mapMoveEnd() {
      let lat = this.map.getCenter().lat;
      let lng = this.map.getCenter().lng;
      let obj = {
        lat: lat,
        lng: lng,
      };
      this.$emit("mapMoveEnd", obj);
    },
    getAddressByPostion(lat: number, lng: number, success: any) {
      var lnglat = [lng, lat];
      this.geocoder.getAddress(lnglat, function (status: any, result: any) {
        if (status === "complete" && result.info === "OK") {
          success(result);
        }
      });
    },
    getPostionByAddress(adderss: string, success: any) {
      this.geocoder.getLocation(adderss, (status: any, result: any) => {
        if (status === "complete" && result.info === "OK") {
          success(
            result.geocodes[0].location.lat,
            result.geocodes[0].location.lng
          );
        }
      });
    },
    removeDevice(type: number) {
      let index = -1;
      for (let i = 0; i < this.devices.length; i++) {
        if (this.devices[i].type == type) {
          index = i;
        }
      }
      if (index != -1) {
        this.devices[index].devs.forEach((x: any) => {
          x.setMap(null);
        });
        this.devices.splice(index, 1);
      }
    },
    removeDeviceLay(type: number) {
      let index = -1;
      for (let i = 0; i < this.devices.length; i++) {
        if (this.devices[i].type == type) {
          index = i;
        }
      }
      if (index != -1) {
        this.labelsLay.remove(this.devices[index].devs);
        this.devices.splice(index, 1);
      }
    },
    addDeviceLay(devices: any[], type: number, pic: any, iconSize: any) {
      this.removeDeviceLay(type);
      const dvs = {
        type: type,
        devs: [] as any[],
      };
      const AMap: any = (window as any).AMap;
      let rpic = pic;
      
      const text = {
        content: "", //要展示的文字内容
        direction: "right", //文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
        offset: [-20, -5], //在 direction 基础上的偏移量
        //文字样式
        style: {
          fontSize: 12, //字体大小
          fillColor: "#22886f", //字体颜色
          strokeColor: "#fff", //描边颜色
          strokeWidth: 2, //描边宽度
        },
      };
      devices.forEach((device: any) => {
        let icon = {
        type: "image", //图标类型，现阶段只支持 image 类型
        image: device.pic||rpic, //可访问的图片 URL
        size: iconSize || new AMap.Size(20, 30), //图片尺寸
        anchor: "center", //图片相对 position 的锚点，默认为 bottom-center
      };
        // text.content = device.deviceName;
        const labelMarker = new AMap.LabelMarker({
          name: device.sid, //此属性非绘制文字内容，仅为标识使用
          position: [device.lng, device.lat],
          zIndex: 16,
          rank: 1, //避让优先级
          icon: icon, //标注图标，将 icon 对象传给 icon 属性
          text: text, //标注文本，将 text 对象传给 text 属性
          draggable: true,
          sid:device.sid
        });
        labelMarker.on("click", (data: any) => {
          this.$emit("devClick", device, type, data, labelMarker);
        });
        labelMarker.on("dragend", (data: any) => {
          this.$emit("dragend", device, type, data);
        });
        this.labelsLay.add(labelMarker);
          dvs.devs.push(labelMarker);
      });
      this.map.setFitView();
      this.devices.push(dvs);
    },
    reAddDeviceLay(device:any,type:number,lat:number,lng:number){
      let index = -1;
      for (let i = 0; i < this.devices.length; i++) {
        if (this.devices[i].type == type) {
          index = i;
        }
      }
      const AMap: any = (window as any).AMap;
      let rindex=-1;
      if (index != -1) {
        for(let i=0;i<this.devices[index].devs.length;i++){
          if(this.devices[index].devs[i].De.sid==device.sid){
            rindex=i;
          }
        }
      }
      if(rindex==-1){
        return;
      }
      this.labelsLay.remove([this.devices[index].devs[rindex]]);
      this.devices[index].devs.splice(rindex,1);
      let icon = {
        type: "image", //图标类型，现阶段只支持 image 类型
        image: device.pic, //可访问的图片 URL
        size:  new AMap.Size(25, 40), //图片尺寸
        anchor: "center", //图片相对 position 的锚点，默认为 bottom-center
      }
      const labelMarker = new AMap.LabelMarker({
          name: device.sid, //此属性非绘制文字内容，仅为标识使用
          position: [lng, lat],
          zIndex: 16,
          rank: 1, //避让优先级
          icon: icon, //标注图标，将 icon 对象传给 icon 属性
          text: "", //标注文本，将 text 对象传给 text 属性
          draggable: true,
          sid:device.sid
        });
        labelMarker.on("click", (data: any) => {
          this.$emit("devClick", device, type, data, labelMarker);
        });
        labelMarker.on("dragend", (data: any) => {
          this.$emit("dragend", device, type, data);
        });
        this.labelsLay.add(labelMarker);
        this.devices[index].devs.push(labelMarker);
    },
    
    addDevice(devices: any[], type: number, pic: any, iconSize: any) {
      this.removeDevice(type);
      const dvs = {
        type: type,
        devs: [] as any[],
      };
      const AMap: any = (window as any).AMap;
      iconSize = iconSize || new AMap.Size(25, 40);
      devices.forEach((x) => {
        if (x.pic) {
          pic = x.pic;
        }
        const icon = new AMap.Icon({
          size: iconSize,
          image: pic,
          imageSize: iconSize,
        });
        const marker = new AMap.Marker({
          position: [x.lng, x.lat],
          icon: icon,
          title: x.deviceName,
          sid: x.sid,
          draggable: true,
        });
        marker.on("dragend", (data: any) => {
          this.$emit("dragend", x, type, data);
        });
        marker.on("click", (data: any) => {
          this.$emit("devClick", x, type, data, marker);
        });
        if (this.map && this.map.add) {
          this.map.add(marker);
          dvs.devs.push(marker);
        }
      });
      this.devices.push(dvs);
      this.setFitView();
    },
    resetAlarm(sid: string) {
      let dev: any = null;
      this.devices.forEach((x: any) => {
        x.devs.forEach((y: any) => {
          if (y.w.sid == sid) {
            dev = y;
          }
        });
      });
      if (dev) {
        if (dev.oldIcon) {
          dev.setIcon(dev.oldIcon);
        }
        if (dev.oldTitle) {
          dev.setTitle(dev.oldTitle);
        }
      }
    },
    setPic(sid: string, pic: string) {
      let dev: any = null;
      this.devices.forEach((x: any) => {
        x.devs.forEach((y: any) => {
          if (y.w.sid == sid) {
            dev = y;
          }
        });
      });
      if (dev) {
        const AMap: any = (window as any).AMap;
        const iconSize = new AMap.Size(25, 40);
        const icon = new AMap.Icon({
          size: iconSize,
          image: pic,
          imageSize: iconSize,
        });
        dev.setIcon(icon);
      }
    },
    setAlarm(sid: string, device: any) {
      let dev: any = null;
      this.devices.forEach((x: any) => {
        x.devs.forEach((y: any) => {
          if (y.w.sid == sid) {
            dev = y;
          }
        });
      });
      if (dev) {
        const AMap: any = (window as any).AMap;
        const iconSize = new AMap.Size(25, 40);
        const icon = new AMap.Icon({
          size: iconSize,
          image: "https://kingsunsmart.com/img/stand/alarm-new.gif",
          imageSize: iconSize,
        });
        const bitCom = dev.getIcon();
        dev.oldIcon = bitCom;
        dev.setIcon(icon);
        const title = dev.getName();
        dev.oldTitle = title;
        dev.setTitle(`${title}:发生${device.alarmTypeName}`);
        dev.alarm = true;
      }
    },
    clearDevice(type: number) {
      this.devices.forEach((x) => {
        x.devs.forEach((y: any) => {
          y.setMap(null);
        });
      });
      this.devices = [];
    },
    clearDeviceLay(type: number) {
      this.map.remove(this.labelsLay);
      const win = (window as any).AMap;
      this.labelsLay=new win.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        collision: true, //该层内标注是否避让
        allowCollision: true, //不同标注层之间是否避让  

      });
      this.map.add(this.labelsLay);
      this.devices = [];
    },
    removePolyLine() {
      if (this.polyline) {
        this.map.remove(this.polyline);
        this.polyline = null;
      }
    },
    removPolygon() {
      if (this.polygon) {
        this.map.remove(this.polygon);
        this.polygon = null;
      }
    },
    setFitView() {
      this.map.setFitView(null, false);
    },
    drawPolygon(data: any[]) {
      this.removPolygon();
      const AMap: any = (window as any).AMap;
      const path: any[] = [];
      data.forEach((x) => {
        path.push(new AMap.LngLat(x.lng, x.lat));
      });
      var polygon = new AMap.Polygon({
        path: path,
        borderWeight: 2,
        strokeColor: "red",
      });
      polygon.on("click", () => {
        this.$emit("polygonClick");
      });
      // 将折线添加至地图实例
      this.map.add(polygon);
      this.polygon = polygon;
    },
    removePolyLines() {
      if (this.polyLines) {
        this.polyLines.forEach((x) => {
          this.map.remove(x);
        });
      }
      this.polyLines = [];
    },
    drawPolyLines(data: any[]) {
      this.removePolyLines();
      data.forEach((x) => {
        const path = [] as any[];
        const AMap: any = (window as any).AMap;
        x.data.forEach((y: any) => {
          path.push(new AMap.LngLat(y.lng, y.lat));
        });
        // 创建折线实例
        var polyline = new AMap.Polyline({
          path: path,
          borderWeight: 2, // 线条宽度，默认为 1
          strokeColor: x.color, // 线条颜色
          lineJoin: "round", // 折线拐点连接处样式
          bubble: true,
        });
        this.map.add(polyline);
        this.polyLines.push(polyline);
      });
    },
    drawPolyLine(data: any[], moveData?: any) {
      this.removePolyLine();
      const path = [] as any[];
      const AMap: any = (window as any).AMap;
      data.forEach((x) => {
        path.push(new AMap.LngLat(x.lng, x.lat));
      });
      if (moveData) {
        path.push(new AMap.LngLat(moveData.lng, moveData.lat));
      }
      // 创建折线实例
      var polyline = new AMap.Polyline({
        path: path,
        borderWeight: 2, // 线条宽度，默认为 1
        strokeColor: "red", // 线条颜色
        lineJoin: "round", // 折线拐点连接处样式
        bubble: true,
      });
      // 将折线添加至地图实例
      this.map.add(polyline);
      this.polyline = polyline;
    },
  },
});
