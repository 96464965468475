
import Vue from "vue";
import box from "@/components/BoxCom.vue";
import mapCom from "@/components/MapCom.vue";
import dialogCom from "@/components/ModelCom.vue";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import { CtrlType, EchartInitModel } from "@/assets/js/Model/InnerModel";
import chooseDialog from "./ShareComponents/ChooseDeviceCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import lineChar from "@/components/Charts/RLineChartCom.vue";
import { getColors } from "@/assets/js/EchartUtil";
import searchInput from "@/components/SearchInputCom.vue"
export default Vue.extend({
  components: { box, mapCom, chooseDialog, dialogCom, lineChar,searchInput },
  data() {
    return {
      loopSid: "" as string,
      currentDev: {} as any,
      Colors: getColors(),
      siwtchValue: true,
      sliderValue: 20,
      mapDialogShow: false,
      groupLable: "请选择分组",
      lightCrl: {
        devices: [],
        action: 0,
        value: 0,
      },
      batchTitle: {
        title: "更新",
      },
      batchType: 0,
      batchForm: {
        devices: [],
        sid: "" as string,
      },
      batchSids: [] as any[],
      choosedItem: [] as any[],
      polyData: [] as any[],
      lights: [] as any[],
      boxs: [] as any[],
      currentAction: 0,
      editAction: 0,
      editing: false,
      mapedDevice: [] as any,
      mapData: {
        boxs: [] as any[],
        lights: [] as any[],
        softwareGroups: [] as any[],
        groups: [] as any[],
      } as any,
      batchDevices: [] as any[],
      loops: [] as any,
      isLoop: false,
      showLoop: false,
      lightAlarm: {} as any,
      gyDialogShow: false,
      currentGateway: {
        gatewayLoops: [],
      } as any,
      engerChart: {} as any,
      images:{
        box:require("../../assets/img/icon/box.png"),
        ctrlAlarm:require("../../assets/img/icon/ctrl-alarm.png"),
        ctrlNormal:require("../../assets/img/icon/ctrl-normal.png"),
        ctrlOffline:require("../../assets/img/icon/ctrl-offline.png"),
        eded:require("../../assets/img/icon/eded.png"),
        standNormal:require("../../assets/img/icon/stand-normal.png"),
      },
      search:""
    };
  },
  watch: {
    "$store.state.alarmAction"() {
      const list: any[] = this.$store.state.alarmList;
      const alarmList = Utils.ArrayGroupBy(list, (item: any) => {
        return item.deviceSid;
      });
      for (let item in alarmList) {
        if (alarmList[item].length != 0) {
          const map: any = this.$refs.map;
          map.setAlarm(item, alarmList[item][0]);
        }
      }
    },
    async "$store.state.loopAction"() {
      await this.getData(true);
      await this.getLoops(true);
      this.change(0);
      if (this.showLoop) {
        this.showLoopChange(false);
        this.showLoopChange(true);
      }
    },
    "$store.state.deviceStateAction"(){
      //先找到设备
      const data:any=this.$store.state.deviceState;
      if(data){
        const type=data.categorySid;
        let pic="";
        let dev:any=null;
        if(type=="0"){
          dev=this.mapData.lights.find((x:any)=>x.sid==data.sid);
          if(dev){
            dev.online=data.type==1?1:0;
            if(dev.online==1){
              pic=this.images.ctrlNormal;
            }else{
              pic=this.images.ctrlOffline;
            }
            const map: any = this.$refs.map;
            map.setPic(dev.sid,pic);
          }
        }
      }
    }
  },
  async mounted() {
    const map: any = this.$refs.map;
    map.init();
    await this.getData();
    await this.getLoops();
    this.ligthChange(0);
  },
  methods: {
    searchChanged(search:string) {
      this.search=search;
    },
    searchClick(search:string){
      this.search=search;
      let lights:any[]=this.mapData.lights;
      let boxs:any[]=this.mapData.boxs;
      if(this.search&&this.search.length!=0){
        // lights=lights.filter((x:any)=>this.search.includes(x.boxName)||this.search.includes(x.deviceName)||this.search.includes(x.deviceTypeName)
        // ||this.search.includes(x.groupName)||this.search.includes(x.ctrlAddr)
        // );
         lights=lights.filter((x:any)=>x.boxName.includes(this.search)||x.deviceName.includes(this.search)||x.deviceTypeName.includes(this.search)
        ||x.groupName.includes(this.search)||x.ctrlAddr.includes(this.search)
        );
        boxs=boxs.filter((x:any)=>this.search.includes(x.deviceName)||this.search.includes(x.deviceTypeName)||this.search.includes(x.groupName));
        console.log(lights);
      }
      this.addDevice(lights,0,false);
      this.addDevice(boxs,2,true);
    },
    async getEngerByMonth() {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetGatewayChartByMonth,
        {sid:this.currentGateway.sid,month:null},
        true,
        false
      );
      this.engerChart = ret;
      this.initEngChart();
    },
    initEngChart() {
      const labels: string[] = [];
      const datas: number[] = [];
      let title = "本年能耗分析";

      this.engerChart.forEach((x: any) => {
        labels.push(x.date);
        datas.push(x.energy);
        title = "本月能耗分析";
      });
      const dchart: any = this.$refs.engChart;
      const data: EchartInitModel = {
        Labels: labels,
        Data: [
          {
            Name: title,
            Data: datas,
            Arear: {
              opacity: 0.3,
            },
          },
        ],
        isXValue: false,
        isInBar: false,
        Style: 0,
        LegendConfig: {
          textStyle: {
            color: "#fff",
          },
          top: "10",
        },
      };
      dchart.setOption(data);
    },
    showLoopChange(val: boolean) {
      const data: any[] = [];
      if (val) {
        this.loops.forEach((x: any) => {
          if (x.loopLine) {
            let color = "blue";
            if (x.state == 0) {
              color = "gray";
            } else {
              if (x.isOpen && x.feedBack) {
                color = "green";
              } else if (x.isOpen && !x.feedBack) {
                color = "red";
              } else if (!x.isOpen) {
                color = "yellow";
              }
            }
            const ps = JSON.parse(x.loopLine);
            const box = this.mapData.boxs.find((y: any) => y.sid == x.boxSid);
            if (box) {
              const psd: any[] = [];
              psd[0] = { lat: box.lat, lng: box.lng };
              for (let i = 0; i < ps.length; i++) {
                psd[i + 1] = ps[i];
              }
              data.push({
                color: color,
                data: psd,
              });
            }
          }
        });
        if (data.length != 0) {
          const map: any = this.$refs.map;
          map.drawPolyLines(data);
          this.boxClick(true);
        }
      } else {
        const map: any = this.$refs.map;
        map.removePolyLines(data);
        map.removeDeviceLay(2);
      }
    },
    async getLoops(noFresh?: boolean) {
      const needFresh = noFresh || false;
      var req = {
        conditions: [
          {
            field: "state",
            value: "1",
            conditionType: 0,
          },
        ],
      };
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetGatewayLoopList,
        req,
        true,
        needFresh
      );
      this.loops = ret.data;
    },
    async switchChange(val: any) {
      if (!this.currentDev || !this.currentDev.ctrlAddr) {
        throw "请选择一个单灯控制器";
      }
      const ids = [this.currentDev.ctrlAddr];
      const type = val ? CtrlType.开灯 : CtrlType.关灯;
      await DeviceRequestBill.CtrLightBySignal(ids, type, 0);
    },
    async sliderChange(val: any) {
      if (!this.currentDev || !this.currentDev.ctrlAddr) {
        throw "请选择一个单灯控制器";
      }
      const ids = [this.currentDev.ctrlAddr];
      const type = CtrlType.调光;
      await DeviceRequestBill.CtrLightBySignal(ids, type, val);
    },
    async initLinechar() {
      if (!this.currentDev || !this.currentDev.ctrlAddr) {
        throw "请选择一个单灯控制器";
      }
      const req = {
        page: 1,
        pageSize: 15,
        conditions: [
          {
            field: "deviceSid",
            value: this.currentDev.sid,
            conditionType: 0,
          },
        ],
        orderByConditions: [
          {
            field: "sid",
            asc: false,
          },
        ],
      };
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetHistoryDatas,
        req,
        true,
        false
      );
      const chart: any = this.$refs.lineChar;
      let lables: string[] = [];
      const data1: number[] = [];
      const data2: number[] = [];
      const data3: number[] = [];
      ret.data.forEach((x: any) => {
        const date = new Date(x.updateTime);
        lables.push(Utils.FormatData(date));
        data1.push(x.voltage);
        data2.push(x.current);
        data3.push(x.power);
      });
      const data: EchartInitModel = {
        NoAuto: true,
        Colors: [this.Colors[0], this.Colors[1], this.Colors[2]],
        Labels: lables,
        Data: [
          {
            Name: "电压",
            Data: data1,
            Arear: {
              opacity: 0.3,
            },
          },
          {
            Name: "电流",
            Data: data2,
            Arear: {
              opacity: 0.3,
            },
          },
          {
            Name: "功率",
            Data: data3,
            Arear: {
              opacity: 0.3,
            },
          },
        ],
        Style: 0,
        LegendConfig: {
          textStyle: {
            color: "#fff",
          },
          top: "10",
        },
      };
      chart.setOption(data);
    },
    async devClick(device: any, type: number, data: any, marker: any) {
      const map: any = this.$refs.map;
      map.resetAlarm(device.sid);
      this.currentDev = device;
      if (
        this.currentDev.deviceTypeSid == "1" ||
        this.currentDev.deviceTypeSid == "2"||
        this.currentDev.deviceTypeSid == "8"
      ) {
        this.sliderValue=this.currentDev.light;
        this.siwtchValue=this.currentDev.open;
        this.mapDialogShow = true;
        this.gyDialogShow = false;
        this.initLinechar();
        await this.getLightState();
      } else if (this.currentDev.deviceTypeSid == "3") {
        this.gyDialogShow = true;
        this.mapDialogShow = false;
        var req = {
          page: 1,
          pageSize: 1,
          conditions: [
            {
              field: "sid",
              value: this.currentDev.gatewaySid,
              conditionType: 0,
            },
          ],
        };
        const ret: any = await HttpUtil.XCHttpPost(
          Urls.GetLoopList,
          req,
          true,
          false
        );
        if (ret.data && ret.data.length != 0) {
          this.currentGateway = ret.data[0];
          await this.getEngerByMonth();
        } else {
          this.currentGateway = {
            gatewayLoops: [],
          };
        }
      }
    },
    async getLightState() {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetLightState,
        this.currentDev.sid,
        true,
        false
      );
      this.lightAlarm = ret;
    },
    change(action: number) {
      this.currentAction = action;
      if (this.currentAction == 0) {
        this.addDevice(this.mapData.lights, 0);
      } else if (this.currentAction == 1) {
        this.lampStand();
      } else if (this.currentAction == 2) {
        this.boxClick();
      } else if (this.currentAction == 3) {
        this.allClick();
      }
    },
    allClick() {
      const map: any = this.$refs.map;
      this.addDevice(this.mapData.lights, 0, true);
      this.boxClick(true);
    },
    boxClick(noClear?: boolean) {
      const map: any = this.$refs.map;
      this.addDevice(this.mapData.boxs, 2, noClear);
    },
    lampStand() {
      const groups = Utils.ArrayGroupBy(this.mapData.lights, (item: any) => {
        return item.lightPoleNo;
      });
      const dvs: any[] = [];
      for (let key in groups) {
        dvs.push({
          sid: `stand${key}`,
          lat: groups[key][0].lat,
          lng: groups[key][0].lng,
          deviceName: groups[key][0].deviceName,
        });
      }
      const map: any = this.$refs.map;
      this.addDevice(dvs, 1,false);
    },
    polygonClick() {
      this.batchSids = [];
      if (this.batchType == 0) {
        this.batchTitle.title = "批量更新设备路段";
        this.groupLable = "请选择路段";
        this.mapData.groups.forEach((x: any) => {
          this.batchSids.push({
            sid: x.sid,
            name: x.groupName,
          });
        });
      } else if (this.batchType == 1) {
        this.batchTitle.title = "批量更新设备电箱";
        this.groupLable = "请选择电箱";
        this.mapData.boxs.forEach((x: any) => {
          this.batchSids.push({
            sid: x.sid,
            name: x.deviceName,
          });
        });
      } else if (this.batchType == 2) {
        this.batchTitle.title = "批量更新设备分组";
        this.groupLable = "请选择软件分组";
        this.mapData.softwareGroups.forEach((x: any) => {
          this.batchSids.push({
            sid: x.sid,
            name: x.groupName,
          });
        });
      }
      if (this.batchType <= 2) {
        // this.batchTitle.title=this.batchType==0?'批量更新设备路段':this.batchType==1?'批量更新设备电箱':'批量更新设备分组';
        this.batchForm.sid = "";
        const dialog: any = this.$refs.dialogGroup;
        dialog.show();
      } else if (this.batchType == 3) {
        const dialog: any = this.$refs.dialogLightCtrl;
        dialog.show();
      }
    },
    async ligthChange(type: number, sub?: string) {
      const map: any = this.$refs.map;
      map.clearDeviceLay();
      this.lights = this.mapData.lights;
      let mapLights = [];
      if (type == 0) {
        if (sub == "online") {
          mapLights = this.mapData.lights.filter((x: any) => x.online == 1);
        } else if (sub == "offline") {
          mapLights = this.mapData.lights.filter((x: any) => x.online == 0);
        } else if (sub == "alarm") {
          mapLights = this.mapData.lights.filter((x: any) => x.state == 0);
        } else {
          mapLights = this.mapData.lights;
        }
      } else if (type == 1) {
        mapLights = this.mapData.lights.filter((x: any) => x.boxSid == sub);
      } else if (type == 2) {
        mapLights = this.mapData.lights.filter((x: any) => x.groupSid == sub);
      } else if (type == 3) {
        const ret: any = await HttpUtil.XCHttpPost(
          Urls.GetSoft,
          sub || "",
          true,
          false
        );
        ret.devices.forEach((x: any) => {
          const exsit = this.mapData.lights.find((y: any) => y.sid == x);
          if (exsit) {
            mapLights.push(exsit);
          }
        });
      }
      this.addDevice(mapLights, 0);
    },
    addDevice(dvs: any[], type: number, noClear?: boolean) {
      const map: any = this.$refs.map;
      if (!noClear) {
        map.clearDeviceLay();
      }
      if (type == 0) {
        map.addDeviceLay(dvs, 0, this.images.eded);
      } else if (type == 1) {
        map.addDeviceLay(
          dvs,
          1,
          this.images.standNormal
        );
      } else if (type == 2) {
        map.addDeviceLay(dvs, 2, this.images.box);
      }
      if (!noClear) {
        this.mapedDevice = dvs;
      } else {
        dvs.forEach((x) => {
          this.mapedDevice.push(x);
        });
      }
    },
    submitCancel() {
      const dialog: any = this.$refs.dialogGroup;
      dialog.hiden();
      this.clearPolygon();
    },
    submitCtlCancel() {
      const dialog: any = this.$refs.dialogLightCtrl;
      dialog.hiden();
      this.clearPolygon();
    },
    async submitCtrl() {
      const data: any = {
        serials: [] as any[],
      };
      let url = Urls.CtrSwitch;
      this.batchDevices.forEach((x) => {
        data.serials.push(x.ctrlAddr);
      });
      if (this.lightCrl.action == 0 || this.lightCrl.action == 1) {
        data.open = this.lightCrl.action == 0 ? true : false;
      } else {
        url = Urls.CtrDim;
        data.value = this.lightCrl.value;
      }
      await HttpUtil.XCHttpPost(url, data, true, false);
      this.submitCtlCancel();
    },
    async submitUpdate() {
      if (Utils.StringIsNull(this.batchForm.sid)) {
        throw "没有选择分组";
      }
      const device: any[] = [];
      this.batchDevices.forEach((x: any) => {
        device.push(x.sid);
      });
      let url = Urls.BatchGroup;
      let data: any = {};
      if (this.batchType == 0) {
        data = {
          groupSid: this.batchForm.sid,
          devices: device,
        };
      } else if (this.batchType == 1) {
        url = Urls.BatchBox;
        data = {
          boxSid: this.batchForm.sid,
          devices: device,
        };
      } else if (this.batchType == 2) {
        url = Urls.BatchSoftGroup;
        data = [
          {
            sid: this.batchForm.sid,
            devices: device,
          },
        ];
      }
      if (device.length != 0) {
        await HttpUtil.XCHttpPost(url, data, true, false);
        this.batchDevices.forEach((x: any) => {
          if (this.batchType == 0) {
            x.groupSid = this.batchForm.sid;
          } else if (this.batchType == 1) {
            x.boxSid = this.batchForm.sid;
          }
        });
        this.submitCancel();
      } else {
        throw "没有选择设备";
      }
    },
    async getData(noFresh?:boolean) {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetMapData,
        [],
        true,
        noFresh
      );
      ret.lights.forEach((x: any) => {
        if (x.online == 1) {
          x.pic = this.images.ctrlNormal;
          if (x.state == 0) {
            x.pic = this.images.ctrlAlarm;
          }
        } else {
          x.pic = this.images.ctrlOffline;
        }
      });
      this.mapData = ret;
    },
    async editPostion(type: number) {
      const dialog: any = this.$refs.chooseDialog;
      dialog.show();
      this.polyData = [];
      this.clearPolcy();
      if (type == 1) {
        this.isLoop = true;
      } else {
        this.isLoop = false;
      }
    },
    hidenEdit() {
      const dialog: any = this.$refs.chooseDialog;
      dialog.hiden();
    },
    onSubmit(items: any, loopSid: any) {
      this.editAction = 0;
      this.hidenEdit();
      this.editing = true;
      this.choosedItem = items;
      this.loopSid = loopSid;
      const map: any = this.$refs.map;
      if(!this.isLoop){
        map.clearDeviceLay();
      }
    },
    ligthGroup(type: number, sub: number) {
      if (type == 0) {
        this.editAction = 1;
        this.editing = true;
        this.polyData = [];
        this.clearPolygon();
        this.batchType = sub;
      }
    },
    clearPolygon() {
      const map: any = this.$refs.map;
      map.removPolygon();
    },
    mapClick(e: any) {
      this.mapDialogShow = false;
      this.gyDialogShow = false;
      if (!this.editing) {
        return;
      }
      this.polyData.push({
        lat: e.lnglat.lat,
        lng: e.lnglat.lng,
      });
      if (this.editAction == 0 || this.editAction == 1) {
        this.drawPolcy(this.polyData);
      }
    },
    mapRightClick(e: any) {
      if (this.editing) {
        if (this.editAction == 0) {
          if (this.polyData.length == 1) {
            this.polyData.push({ lat: e.lnglat.lat, lng: e.lnglat.lng });
          }
          this.drawPolcy(this.polyData);
          this.saveLightData();
        } else if (this.editAction == 1) {
          this.clearPolcy();
          this.drawPolygon(this.polyData);
          const list: any[] = [];
          for (let i = 0; i < this.mapedDevice.length; i++) {
            if (
              Utils.PointerInPolygon(this.polyData, {
                lat: this.mapedDevice[i].lat,
                lng: this.mapedDevice[i].lng,
              })
            ) {
              list.push(this.mapedDevice[i]);
            }
          }
          if (list == null || list.length == 0) {
            throw "当前范围没有设备";
          }
          this.batchDevices = list;
          this.editing = false;
        }
      } else {
        this.clearPolygon();
        this.clearPolcy();
      }
    },
    saveLightData() {
      const pos = Utils.GetNextPosition(this.polyData, this.choosedItem.length);
      for (let i = 0; i < this.choosedItem.length; i++) {
        if (i < pos.length) {
          this.choosedItem[i].lat1 = this.choosedItem[i].lat;
          this.choosedItem[i].lng1 = this.choosedItem[i].lng;
          this.choosedItem[i].lat = pos[i].lat;
          this.choosedItem[i].lng = pos[i].lng;
        } else {
          this.choosedItem[i].lat1 = this.choosedItem[i].lat;
          this.choosedItem[i].lng1 = this.choosedItem[i].lng;
          this.choosedItem[i].lat = pos[pos.length - 1].lat;
          this.choosedItem[i].lng = pos[pos.length - 1].lng;
        }
      }
      this.addDevice(this.choosedItem, 0);
      Utils.ConfirmMsg(
        this,
        "是否更新设备位置信息",
        async () => {
          const data: any[] = [];
          const dataLoop: any[] = [];
          this.choosedItem.forEach((x) => {
            data.push({
              sid: x.sid,
              lat: x.lat,
              lng: x.lng,
            });
            dataLoop.push({
              lightSid: x.sid,
              loopSid: this.loopSid,
            });
          });
          await HttpUtil.XCHttpPost(Urls.BatchPosition, data, true, false);
          const line = JSON.stringify(this.polyData);
          if (this.isLoop && this.loopSid) {
            const dataRw = {
              loopLine: line,
              sid: this.loopSid,
            };
            await HttpUtil.XCHttpPost(Urls.UpdateLoopLine, dataRw, true, false);
            await HttpUtil.XCHttpPost(
              Urls.BatchUpdateLoop,
              dataLoop,
              true,
              false
            );
          }
          this.editing = false;
        },
        () => {
          this.clearPolygon();
          this.clearPolcy();
          this.editing = false;
          this.choosedItem.forEach((x) => {
            x.lat = x.lat1;
            x.lng = x.lng1;
          });
          this.ligthChange(0);
        }
      );
    },
    mapMouseMove(e: any) {
      if (!this.editing) {
        return;
      }
      if (this.polyData.length == 0) {
        return;
      } else {
        if (this.editAction == 0 || this.editAction == 1) {
          this.drawPolcy(this.polyData, {
            lat: e.lnglat.lat,
            lng: e.lnglat.lng,
          });
        }
      }
    },
    drawPolcy(data: any[], moveData?: any) {
      const map: any = this.$refs.map;
      map.drawPolyLine(data, moveData);
    },
    drawPolygon(data: any[]) {
      const map: any = this.$refs.map;
      map.drawPolygon(data);
    },
    async dragend(dev: any, type: number, data: any) {
      const map: any = this.$refs.map;
      let d: any[] ;
      if(data.lnglat){
        d=[
        {
          sid: dev.sid,
          lat: data.lnglat.lat,
          lng: data.lnglat.lng,
        }
      ]
      map.reAddDeviceLay(dev,type,data.lnglat.lat,data.lnglat.lng);
      }else if(data.Of){
        d=[
        {
          sid: dev.sid,
          lat: data.Of.lat,
          lng: data.Of.lng,
        }]
        map.reAddDeviceLay(dev,type,data.Of.lat,data.Of.lng);
      }
      await HttpUtil.XCHttpPost(Urls.BatchPosition, d, true, false);
    },
    clearPolcy() {
      const map: any = this.$refs.map;
      map.removePolyLine();
    },
    async loopChange(isOpen: boolean, sid: string) {
      const data = {
        loopSids: [sid],
        open: isOpen,
      };
      await HttpUtil.XCHttpPost(Urls.ControlLoop, data, true, false);
    },
    async freshPower(){
      await DeviceRequestBill.CtrLightBySignal([this.currentDev.ctrlAddr],CtrlType.电能查询,0);
      await this.getData();
      this.change(0);
      this.mapClick({});
    },
    
  },
});
