
import Vue from "vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
export default Vue.extend({
  components: { action, pageCom, dialogCom ,delCom},
  data() {
    return {
        delUrl:Urls.DelUsers,
        currentSid:"",
      items: [] as any[],
      total: 0,
      pageSize: 20,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增项目"
      },
      projectForm: {} as any,
      current:null as any,
      projects:[] as any[]
    };
  },
  async mounted() {
    await this.getProjects();
    await this.select();
  },
  methods: {
    async getProjects(){
        const dfata:any={
             page: 1,
        pageSize: 1000,
        pagination: true,
        }
        const ret:any=await HttpUtil.XCHttpPost(Urls.GetProjects,dfata,true,false);
        this.projects=ret.data;
        
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = null;
      }
      const data = {
        page: this.currentPage,
        pageSize: this.pageSize,
        pagination: true,
        conditions:! this.searchStr?[]: [
          {
            field: "createUser",
            value: this.searchStr,
            conditionType: 7
          },
          {
            field: "action",
            value: this.searchStr,
            conditionType: 7
          },
          {
            field: "ipAddress",
            value: this.searchStr,
            conditionType: 7
          }
        ]
      };
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.ActionLog,
        data,
        true,
        false
      );
      this.items = ret.data;
      this.total = ret.total;
    },
    currentChange(val:any){
        this.current=val;
    },
    insert() {
      this.current=null;
      this.projectForm={
        sid:null
      }
      this.dialogTitle.title="新增用户";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog(){
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    modify() {
      if(!this.current){
        throw "请选择一条数据";
      }
      this.projectForm=this.current;
      this.dialogTitle.title="修改用户";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    del() {
       if(!this.current){
        throw "请选择一条数据";
      }
      this.currentSid=this.current.sid;
      const dialog: any = this.$refs.del;
      dialog.show();
    },
    ex() {
      //
    },
    inport() {
      //
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number,size:number) {
      this.currentPage = val;
      this.pageSize=size;
      this.select();
    },
    async onSubmit(){
        if(!this.projectForm.sid){
            await HttpUtil.XCHttpPost(Urls.AddUser,this.projectForm,true,false);
        }else{
            await HttpUtil.XCHttpPost(Urls.UpdateUser,this.projectForm,true,false);
        }
        this.closeDialog();
        this.select();
    },
  }
});
