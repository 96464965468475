import HttpUtil from "../HttpUtil"
import { BoxDetail, DeviceTypeDetail, GatewayDetail, RouterDetail, PagedResponse, LightDetail, LightControlDetail,
     LoopDetail, PolicyDetail, SoftGroupDetail, TaskListDetail, TaskDetail, TaskLogDetail, HisotryDataDetail, 
     DeviceAlarmDetail, ScreenLocalDetail, PlatPolicyList, platSubPolicyDetail, PlatPolicyDetail } from "../Model/ResponseModel";
import utils from "@/assets/js/Utils"
import Urls from "../Urls"
import { CtrlType } from "../Model/InnerModel";
const GetRouters = (search: any, page: number, pageSize: number): Promise<PagedResponse<RouterDetail>> => {
    const array: string[] = ["createUserName", "groupName"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    return HttpUtil.XCHttpPost<any, PagedResponse<RouterDetail>>(Urls.GetGroups, req, true, false);
}
const GetBoxs = (search: any, page: number, pageSize: number): Promise<PagedResponse<BoxDetail>> => {
    const array: string[] = ["createUserName", "groupName", "deviceTypeName", "deviceName", "gatewayName"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    return HttpUtil.XCHttpPost<any, PagedResponse<BoxDetail>>(Urls.GetBoxs, req, true, false);
}
const GetPlatPolicyList = (search: any, page: number, pageSize: number): Promise<PagedResponse<PlatPolicyList>> => {
    const array: string[] = ["createUserName", "name"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    return HttpUtil.XCHttpPost<any, PagedResponse<PlatPolicyList>>(Urls.GetPlatPolicyList, req, true, false);
}
const GetPlatPolicyDetail = (sid:string): Promise<PagedResponse<PlatPolicyDetail>> => {
    return HttpUtil.XCHttpPost<any, PagedResponse<PlatPolicyDetail>>(Urls.GetPlatPolicy, sid, true, false);
}
const GetGateways = (search: any, page: number, pageSize: number): Promise<PagedResponse<GatewayDetail>> => {
    const array: string[] = ["createUserName", "groupName", "deviceTypeName", "deviceName", "gatewayName"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    return HttpUtil.XCHttpPost<any, PagedResponse<GatewayDetail>>(Urls.GetGateways, req, true, false);
}
const GetDeviceTypes = (): Promise<PagedResponse<DeviceTypeDetail>> => {
    const req = utils.GetPageReq(1, 10000, undefined, []);
    return HttpUtil.XCHttpPost<any, PagedResponse<DeviceTypeDetail>>(Urls.GetDeviceTypes, req, true, false);
}
const GetLights = (search: any, page: number, pageSize: number, deviceTypeSid: any): Promise<PagedResponse<LightDetail>> => {
    const array: string[] = ["createUserName", "groupName", "deviceTypeName", "deviceName", "ctrlAddr","boxName"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    if (deviceTypeSid) {
        req.conditions.push({
            field: "deviceTypeSid",
            value: deviceTypeSid,
            conditionType: 0
        });
    }
    return HttpUtil.XCHttpPost<any, PagedResponse<LightDetail>>(Urls.GetLights, req, true, false);
}
const GetLightCtrs = (search: any, page: number, pageSize: number): Promise<PagedResponse<LightControlDetail>> => {
    const array: string[] = ["createUserName", "groupName", "deviceTypeName", "deviceName", "ctrlAddr","boxName"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    return HttpUtil.XCHttpPost<any, PagedResponse<LightControlDetail>>(Urls.GetLightCtrs, req, true, false);
}
const GetLoops = (search: any, page: number, pageSize: number): Promise<PagedResponse<LoopDetail>> => {
    const array: string[] = ["createUserName", "groupName", "deviceTypeName", "deviceName", "gatewayAddress"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    return HttpUtil.XCHttpPost<any, PagedResponse<LoopDetail>>(Urls.GetLoopList, req, true, false);
}
const GetPolicys = (search: any, page: number, pageSize: number, type?: number): Promise<PagedResponse<PolicyDetail>> => {
    const array: string[] = ["policyName", "isLightPolicy"]
    const req = utils.GetPageReq(page, pageSize, search, array, undefined);
    if(type||type==0){
        if(!req.conditions){
            req.conditions=[];
        }
        req.conditions.push({
            field: "isLightPolicy",
            value: type+"",
            conditionType:0
        })
    }
    return HttpUtil.XCHttpPost<any, PagedResponse<PolicyDetail>>(Urls.GetPolicys, req, true, false);
    
}
const GetPolicyDetail = (sid: string): Promise<PolicyDetail> => {
    return HttpUtil.XCHttpPost<any, PolicyDetail>(Urls.GetPolicy, sid, true, false);
}
const GetSofts = (search: any, page: number, pageSize: number,type?:any): Promise<PagedResponse<SoftGroupDetail>> => {
    const array: string[] = ["createUserName", "groupName"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    if(type||type==0){
        if(!req.conditions){
            req.conditions=[];
        }
        req.conditions.push({
            field: "groupType",
            value: type+"",
            conditionType:0
        })
    }
    return HttpUtil.XCHttpPost<any, PagedResponse<SoftGroupDetail>>(Urls.GetSofts, req, true, false);
}
const GetHards = (search: any, page: number, pageSize: number): Promise<PagedResponse<SoftGroupDetail>> => {
    const array: string[] = ["createUserName", "groupName"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    return HttpUtil.XCHttpPost<any, PagedResponse<SoftGroupDetail>>(Urls.GetHardwareGroups, req, true, false);
}
const GetTasks = (search: any, page: number, pageSize: number): Promise<PagedResponse<TaskListDetail>> => {
    const array: string[] = ["policyTaskName", "createUserName"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    return HttpUtil.XCHttpPost<any, PagedResponse<TaskListDetail>>(Urls.GetTasks, req, true, false);
}
const GetTaskDetail = (sid: string): Promise<TaskDetail> => {
    return HttpUtil.XCHttpPost<any, TaskDetail>(Urls.GetTask, sid, true, false);
}
const GetTaskLog = (page: number, pageSize: number, sid: string): Promise<PagedResponse<TaskLogDetail>> => {
    const array: string[] = [];
    const req = utils.GetPageReq(page, pageSize, undefined, array, 0);
    req.conditions.push({
        field: "policyTaskSid",
        value: sid,
        conditionType: 0
    });
    return HttpUtil.XCHttpPost<any, PagedResponse<TaskLogDetail>>(Urls.GetTaskLogs, req, true, false);
}
const GetHistoryData = (search: any, page: number, pageSize: number): Promise<PagedResponse<HisotryDataDetail>> => {
    const array: string[] = ["deviceName"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    return HttpUtil.XCHttpPost<any, PagedResponse<HisotryDataDetail>>(Urls.GetHistoryDatas, req, true, false);
}
const GetDeviceAlarms = (search: any, page: number, pageSize: number): Promise<PagedResponse<DeviceAlarmDetail>> => {
    const array: string[] = ["deviceName"]
    const req = utils.GetPageReq(page, pageSize, search, array);
    return HttpUtil.XCHttpPost<any, PagedResponse<DeviceAlarmDetail>>(Urls.GetDeviceAlarms, req, true, false);
}
const ExportGatewayeEnger = (year: boolean, sid: string): Promise<string> => {
    const url = year ? Urls.GatewayYearExport : Urls.GatewayMonthExport;
    return HttpUtil.XCHttpPost<string, string>(url, sid, true, false);
}
const SetGatewayTime = (sid: string): Promise<string> => {
    return HttpUtil.XCHttpPost<string, string>(Urls.SetScreenLocalTime, sid, true, false);
}
const GetScreenData = (sid: string): Promise<string> => {
    return HttpUtil.XCHttpPost<string, string>(Urls.GetScreenData, sid, true, false);
}
const SetScreenPassword = (serial: string, password: string): Promise<string> => {
    const obj = {
        serial: serial,
        password: password
    }
    return HttpUtil.XCHttpPost<any, string>(Urls.SetScreenPassword, obj, true, false);
}

const SetScreentTimmer = (data: ScreenLocalDetail): Promise<string> => {
    return HttpUtil.XCHttpPost<ScreenLocalDetail, string>(Urls.SetScreentTimmer, data, true, false);
}
const CtrLightBySignal = (ids: string[], ctrlType: CtrlType, lightValue: number): Promise<boolean> => {
    if (ids.length == 0) {
        throw "请选择灯具";
    }
    let url = "";
    const data: any = {
        serials: ids
    };
    switch (ctrlType) {
        case CtrlType.关灯:
            url = Urls.CtrSwitch;
            data.open = false;
            break;
        case CtrlType.开灯:
            url = Urls.CtrSwitch;
            data.open = true;
            break;
        case CtrlType.调光:
            url = Urls.CtrDim;
            data.value = lightValue;
            break;
        case CtrlType.读取数据:
            url = Urls.CtrRead;
            break;
        case CtrlType.同步时间:
            url = Urls.CtrSetLocalTime;
            break;
        case CtrlType.读取时间:
            url = Urls.CtrGetLocalTime;
            break;
        case CtrlType.电能查询:
            url = Urls.CtrGetEnergy;
            break;
        case CtrlType.灯具状态:
            url = Urls.CtrGetLightState;
            break;
        case CtrlType.日出日落时间查询:
            url = Urls.CtrGetSunRiseTime;
            break;
    }
    return HttpUtil.XCHttpPost<any, boolean>(url, data, true, false);
}
const CtrLightByBroad = (ctrlType: CtrlType, lightValue: number): Promise<boolean> => {
    let url = "";
    let data: any = null
    switch (ctrlType) {
        case CtrlType.关灯:
            url = Urls.CtrBroadSwitch;
            data = false;
            break;
        case CtrlType.开灯:
            url = Urls.CtrBroadSwitch;
            data = true;
            break;
        case CtrlType.调光:
            url = Urls.CtrBroadDim;
            data = lightValue;
            break;
        case CtrlType.读取数据:
            url = Urls.CtrBroadRead;
            break;
        case CtrlType.同步时间:
            url = Urls.CtrBroadSetLocalTime;
            break;
        case CtrlType.读取时间:
            url = Urls.CtrBroadGetLocalTime;
            break;
        case CtrlType.电能查询:
            url = Urls.CtrBroadGetEnergy;
            break;
        case CtrlType.灯具状态:
            url = Urls.CtrBroadGetLightState;
            break;
        case CtrlType.日出日落时间查询:
            url = Urls.CtrBroadcastGetSunRiseTime;
            break;
    }
    return HttpUtil.XCHttpPost<any, boolean>(url, data, true, false);
}
const CtrLightByBox = (ids: string[], ctrlType: CtrlType, lightValue: number): Promise<boolean> => {
    if (ids.length == 0) {
        throw "请选择电箱";
    }
    let url = "";
    const data: any = {
        boxSids: ids
    }
    switch (ctrlType) {
        case CtrlType.关灯:
            url = Urls.CtrBoxSwitch;
            data.open = false;
            break;
        case CtrlType.开灯:
            url = Urls.CtrBoxSwitch;
            data.open = true;
            break;
        case CtrlType.调光:
            url = Urls.CtrBoxDim;
            data.value = lightValue;
            break;
        case CtrlType.读取数据:
            url = Urls.CtrBoxRead;
            break;
        case CtrlType.同步时间:
            url = Urls.CtrBoxSetLocalTime;
            break;
        case CtrlType.读取时间:
            url = Urls.CtrBoxGetLocalTime;
            break;
        case CtrlType.电能查询:
            url = Urls.CtrBoxGetEnergy;
            break;
        case CtrlType.灯具状态:
            url = Urls.CtrBoxGetLightState;
            break;
        case CtrlType.日出日落时间查询:
            url = Urls.CtrBoxGetSunRiseTime;
            break;
    }
    return HttpUtil.XCHttpPost<any, boolean>(url, data, true, false);
}
const CtrLightByRouter = (ids: string[], ctrlType: CtrlType, lightValue: number): Promise<boolean> => {
    if (ids.length == 0) {
        throw "请选择路段";
    }
    let url = "";
    const data: any = {
        groupSids: ids
    }
    switch (ctrlType) {
        case CtrlType.关灯:
            url = Urls.CtrGroupSwitch;
            data.open = false;
            break;
        case CtrlType.开灯:
            url = Urls.CtrGroupSwitch;
            data.open = true;
            break;
        case CtrlType.调光:
            url = Urls.CtrGroupDim;
            data.value = lightValue;
            break;
        case CtrlType.读取数据:
            url = Urls.CtrGroupDim;
            break;
        case CtrlType.同步时间:
            url = Urls.CtrGroupSetLocalTime;
            break;
        case CtrlType.读取时间:
            url = Urls.CtrGroupGetLightState;
            break;
        case CtrlType.电能查询:
            url = Urls.CtrGroupGetEnergy;
            break;
        case CtrlType.灯具状态:
            url = Urls.CtrGroupGetLightState;
            break;
        case CtrlType.日出日落时间查询:
            url = Urls.CtrGroupGetSunRiseTime;
            break;
    }
    return HttpUtil.XCHttpPost<any, boolean>(url, data, true, false);
}
const CtrLightBySoft = (ids: string[], ctrlType: CtrlType, lightValue: number): Promise<boolean> => {
    if (ids.length == 0) {
        throw "请选择分组";
    }
    let url = "";
    const data: any = {
        softwareGroupSids: ids
    }
    switch (ctrlType) {
        case CtrlType.关灯:
            url = Urls.CtrSoftSwitch;
            data.open = false;
            break;
        case CtrlType.开灯:
            url = Urls.CtrSoftSwitch;
            data.open = true;
            break;
        case CtrlType.调光:
            url = Urls.CtrSoftDim;
            data.value = lightValue;
            break;
        case CtrlType.读取数据:
            url = Urls.CtrSoftRead;
            break;
        case CtrlType.同步时间:
            url = Urls.CtrSoftSetLocalTime;
            break;
        case CtrlType.读取时间:
            url = Urls.CtrSoftGetLocalTime;
            break;
        case CtrlType.电能查询:
            url = Urls.CtrSoftGetEnergy;
            break;
        case CtrlType.灯具状态:
            url = Urls.CtrSoftGetLightState;
            break;
        case CtrlType.日出日落时间查询:
            url = Urls.CtrSoftcastGetSunRiseTime;
            break;
    }
    return HttpUtil.XCHttpPost<any, boolean>(url, data, true, false);
}
const CtrLightByHard = (ids: string[], ctrlType: CtrlType, lightValue: number): Promise<boolean> => {
    if (ids.length == 0) {
        throw "请选择分组";
    }
    let url = "";
    const data: any = {
        groupSids: ids
    }
    switch (ctrlType) {
        case CtrlType.关灯:
            url = Urls.SwitchHardwareGroup;
            data.open = false;
            break;
        case CtrlType.开灯:
            url = Urls.SwitchHardwareGroup;
            data.open = true;
            break;
        case CtrlType.调光:
            url = Urls.DimHardwareGroup;
            data.value = lightValue;
            break;
    }
    return HttpUtil.XCHttpPost<any, boolean>(url, data, true, false);
}
export default {
    CtrLightByHard: CtrLightByHard,
    GetRouters: GetRouters,
    GetBoxs: GetBoxs,
    GetGateways: GetGateways,
    GetDeviceTypes: GetDeviceTypes,
    GetLights: GetLights,
    GetLightCtrs: GetLightCtrs,
    CtrLightByBox: CtrLightByBox,
    CtrLightByBroad: CtrLightByBroad,
    CtrLightByRouter: CtrLightByRouter,
    CtrLightBySignal: CtrLightBySignal,
    GetLoops: GetLoops,
    GetPolicys: GetPolicys,
    GetPolicyDetail: GetPolicyDetail,
    GetSofts: GetSofts,
    GetTasks: GetTasks,
    GetTaskDetail: GetTaskDetail,
    GetTaskLog: GetTaskLog,
    GetHistoryData: GetHistoryData,
    GetDeviceAlarms: GetDeviceAlarms,
    CtrLightBySoft: CtrLightBySoft,
    ExportGatewayeEnger: ExportGatewayeEnger,
    SetGatewayTime: SetGatewayTime,
    GetScreenData: GetScreenData,
    SetScreenPassword: SetScreenPassword,
    SetScreentTimmer: SetScreentTimmer,
    GetHards: GetHards,
    GetPlatPolicyList:GetPlatPolicyList,
    GetPlatPolicyDetail:GetPlatPolicyDetail
}