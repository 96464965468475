
import Vue from "vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import { StoreTypes } from "@/assets/js/Types";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
export default Vue.extend({
  components: { action, pageCom, dialogCom, delCom },
  data() {
    return {
      delUrl: Urls.DelPlatPolicy,
      currentSid: "",
      items: [] as any[],
      total: 0,
      pageSize: 20,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增策略",
      },
      projectForm: {
        sid: null,
        trigerType: 0,
        name: "",
        trigerTime: "",
        enable: 0,
        platSubPolicys: [] as any,
      } as any,
      current: null as any,
      projects: [] as any[],
      step: 0,
      policySEDate: [] as any[],
      itemDevs: [] as any[],
      subCurrentPage:1,
      subTotal:0,
      subPageSize:5,
      lightList:[]as any[],
      ligthListTotal:0,
      gatewayList:[]as any[],
      gatewayListTotal:0,
      softList:[]as any[],
      softListTotal:0,
      loopList:[]as any[],
      loopListTotal:0,
    };
  },
  async mounted() {
    await this.select();
    await this.getGateways();
    await this.getLights();
    await this.getLoops();
  },
  methods: {
    subChange(val: number, size: number,sid:string) {
      this.subCurrentPage = val;
      this.subPageSize = size;
      this.lightList=[];
      this.softList=[];
      this.loopList=[];
      this.gatewayList=[];
      this.devChange(sid);
    },
    SEChange() {
      this.projectForm.startTime = this.policySEDate[0];
      this.projectForm.endTime = this.policySEDate[1];
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = null;
      }
      const ret = await DeviceRequestBill.GetPlatPolicyList(
        this.searchStr,
        this.currentPage,
        this.pageSize
      );
      this.items = ret.data;
      this.total = ret.total;
    },
    currentChange(val: any) {
      this.current = val;
    },
    addTimmer() {
      if (!this.projectForm.platSubPolicys) {
        this.projectForm.platSubPolicys = [];
      }
      if (this.projectForm.platSubPolicys.length >= 8) {
        throw "最多支持8个策略同时执行";
      }
      const sid=Utils.GetGuid();
      this.projectForm.platSubPolicys.push({
        sid: sid,
        type: 0,
        action: 0,
        triger: 0,
        delay: 0,
        order: 0,
        items: [],
        devs: [],
      });
      this.retSetOrder();
      this.devChange(sid);
    },
    removeTimmer(sid: string) {
      let index = -1;
      for (let i = 0; i < this.projectForm.platSubPolicys.length; i++) {
        if (this.projectForm.platSubPolicys[i].sid == sid) {
          index = i;
        }
      }
      this.projectForm.platSubPolicys.splice(index, 1);
      this.retSetOrder();
    },
    retSetOrder() {
      let i = 1;
      this.projectForm.platSubPolicys.forEach((x: any) => {
        x.order = i;
        i += 1;
      });
    },
    insert() {
      this.projectForm = {
        sid: null,
        trigerType: 0,
        name: "",
        trigerTime: "",
        enable: 0,
        platSubPolicys: [] as any,
      };
      this.dialogTitle.title = "新增策略";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog() {
       const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    async modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.dialogTitle.title="修改策略";
      const reslut = await DeviceRequestBill.GetPlatPolicyDetail(this.current.sid);
      this.projectForm=reslut;
      this.projectForm.platSubPolicys.forEach((x:any) => {
        this.devChange(x.sid,true);
      });
      this.policySEDate[0]=new Date(this.projectForm.startTime);
      this.policySEDate[1]=new Date(this.projectForm.endTime);
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    async getLights() {
      if(this.lightList.length==0){
        const list = await DeviceRequestBill.GetLights(null,this.subCurrentPage,this.subPageSize,null);
        this.ligthListTotal=list.total;
        if(list&&list.data){
          list.data.forEach(x=>{
            this.lightList.push({
              sid:x.sid,
              name:x.deviceName
            })
          });
        }
      }
      return this.lightList;
    },
    async getLoops() {
      if(this.loopList.length==0){
         let req={
            page:this.subCurrentPage,
            pageSize:this.subPageSize,
            conditions:[{
                field:"state",
                value:"1",
                conditionType:0 
            }
            ],
            orderByConditions:[{
                field:"gatewaySid",
                asc:true
            }]
        }
        const list:any = await HttpUtil.XCHttpPost(Urls.GetGatewayLoopList,req,true,false);
        this.loopListTotal=list.total;
        if(list&&list.data){
          list.data.forEach(x=>{
            this.loopList.push({
              sid:x.sid,
              name:`${x.gatewayName}-回路${x.order}:${x.loopName}`
            })
          });
        }
      }
      return this.loopList;
    },
    async getSofts() {
      if(this.softList.length==0){
        const list = await DeviceRequestBill.GetSofts(null,this.subCurrentPage,this.subPageSize);
        this.softListTotal=list.total;
        if(list&&list.data){
          list.data.forEach(x=>{
            this.softList.push({
              sid:x.sid,
              name:x.groupName
            })
          });
        }
      }
      return this.softList;
    },
    async getGateways() {
      if(this.gatewayList.length==0){
        const list = await DeviceRequestBill.GetGateways(null,this.subCurrentPage,this.subPageSize);
        this.gatewayListTotal=list.total;
        if(list&&list.data){
          list.data.forEach(x=>{
            this.gatewayList.push({
              sid:x.sid,
              name:x.deviceName
            })
          });
        }
      }
      return this.gatewayList;
    },
    async devChange(sid: string,ret?:boolean) {
      //找到数据
      const item = this.projectForm.platSubPolicys.find(
        (r: any) => r.sid == sid
      );
      if (item) {
        if(!ret){
        item.items=[];

        }
        switch (item.type) {
          case 0:
           item.devs= await this.getLights();
           this.subTotal=this.ligthListTotal;
            break;
          case 1:
           item.devs= await this.getGateways();
           this.subTotal=this.gatewayListTotal;
            break;
          case 2:
           item.devs= await this.getSofts();
           this.subTotal=this.softListTotal;
            break;
          case 3:
           item.devs= await this.getLoops();
           this.subTotal=this.loopListTotal;
            break;
        }
      }
    },
    del() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.currentSid = this.current.sid;
      const dialog: any = this.$refs.del;
      dialog.show();
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
    async down(){
       if (!this.current) {
        throw "请选择一条数据";
      }
      await HttpUtil.XCHttpPost(Urls.EexcutePlatTask,this.current.sid,true,false);
    },
    async onSubmit() {
      this.projectForm.startTime=this.policySEDate[0];
      this.projectForm.endTime=this.policySEDate[1];
      if(!this.projectForm.trigerTime){
        this.projectForm.trigerTime=new Date().toDateString();
      }
      this.projectForm.platSubPolicys.forEach((element:any) => {
        element.delay=element.delay*1;
        element.actionValue=element.actionValue*1;
        if(!element.actionValue){
          element.actionValue=0;
        }
      });
      if(!this.projectForm.sid){
        await HttpUtil.XCHttpPost(Urls.AddPlatPolicy,this.projectForm,true);
      }else{
        await HttpUtil.XCHttpPost(Urls.UpdatePlatPolicy,this.projectForm,true);
      }
      this.closeDialog();
      this.select();
    },
  },
});
